<template>
  <mobile-screen
    :header="true"
    screen-class="edit-form-screen data-regions icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: 'r_data-regions' }">
            <icon icon="#cx-hea1-arrow-left"></icon>
          </router-link>
        </template>
        <div class="component-title">
          Edit data region
        </div>
        <template v-slot:right>
          <button @click="initSubmit">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      v-if="selectedDataRegion"
      @submit="submitForm"
      v-slot="{ errors }"
      class="form"
      novalidate
    >
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.name }"
        >
          <span class="error-message" v-if="errors.name">
            {{ errors.name }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label for="name" class="clebex-section-input-label">
                Name
              </label>
              <Field
                name="name"
                as="input"
                type="text"
                rules="required"
                id="name"
                :disabled="submitting"
                v-model="selectedDataRegion.name"
              />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.db_host }"
        >
          <span class="error-message" v-if="errors.db_host">
            {{ errors.db_host }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label for="dbHost" class="clebex-section-input-label">
                DB Host
              </label>
              <Field
                name="db_host"
                as="input"
                type="text"
                rules="required"
                id="dbHost"
                :disabled="submitting"
                v-model="selectedDataRegion.db_host"
              />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.db_port }"
        >
          <span class="error-message" v-if="errors.db_port">
            {{ errors.db_port }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label for="dbPort" class="clebex-section-input-label">
                DB Port
              </label>
              <Field
                name="db_port"
                as="input"
                type="text"
                rules="required|numeric"
                id="dbPort"
                :disabled="submitting"
                v-model="selectedDataRegion.db_port"
              />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.db_sslmode }"
        >
          <span class="error-message" v-if="errors.db_sslmode">
            {{ errors.db_sslmode }}
          </span>
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    name="db_sslmode"
                    type="checkbox"
                    id="dbSslMode"
                    :checked="sslMode"
                    v-model="sslMode"
                  />
                  <label for="dbSslMode"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                DB SSL Mode
              </dd>
            </dl>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.storage_location }"
        >
          <span class="error-message" v-if="errors.storage_location">
            {{ errors.storage_location }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label for="storageLocation" class="clebex-section-input-label">
                Storage Location
              </label>
              <Field
                name="storage_location"
                as="input"
                type="text"
                rules="required"
                id="storageLocation"
                :disabled="submitting"
                v-model="selectedDataRegion.storage_location"
              />
            </div>
          </div>
        </li>
      </ul>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import httpServiceAuth from "@/services/http-service";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import MobileScreen from "@/layouts/MobileScreen";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import { mapState, mapActions } from "vuex";

export default {
  name: "EditDataRegion",
  data() {
    return {
      submitting: false
    };
  },
  computed: {
    ...mapState("dataRegion", ["selectedDataRegion"]),
    sslMode: {
      get() {
        if (this.selectedDataRegion) {
          return this.selectedDataRegion.db_sslmode ? true : false;
        } else {
          return true;
        }
      },
      set(val) {
        this.selectedDataRegion.db_sslmode = val;
        this.setSelectedDataRegion(this.selectedDataRegion);
      }
    }
  },
  created() {
    if (!this.selectedDataRegion) {
      this.$router.push({ name: "r_data-regions" });
    }
  },
  methods: {
    ...mapActions("dataRegion", ["setSelectedDataRegion"]),
    submitForm(values, { resetForm }) {
      if (values) {
        this.submitting = true;
        this.submitMasterData(values, resetForm);
      }
    },
    submitMasterData(values, resetForm) {
      values.db_sslmode = this.sslMode;
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .put(
          `${apiEndpoints.master.dataRegions}/${this.selectedDataRegion.id}`,
          values
        )
        .then(() => {
          resetForm();
          this.getDataRegions();
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.submitting = false;
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          this.$router.push({ name: "r_data-regions" });
        });
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
